import { accordion } from "./modules/accordion"

$(function () {
  accordion();

  $('a[href*="#"]').on('click', function (e) {
    e.preventDefault();
    let target = $(this.hash);
    if (target.length) {
      $('html, body').animate({
        scrollTop: target.offset().top
      }, 800);
    }
  });
  $('a[href*="#top-link"]').on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 500);
  });
  let pageTop = $('#back-top');
  pageTop.on('click', function () {
    $('body,html').animate({
      scrollTop: 0
    }, 500);
    return false;
  });
});