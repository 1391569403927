const accordion = () => {
  let accordion_toggle = $(".accordion__heading");
  let accordion__collapse = $('.accordion-box__content');
  accordion_toggle.on('click', function () {
    // accordion_toggle.not(this).removeClass('is-open');
    // accordion_toggle.not(this).next($(accordion__collapse)).slideUp(300);
    $(this).toggleClass('is-open');
    $(this).next($(accordion__collapse)).slideToggle(300);
  });
}
export { accordion }
